<template>
  <div>
    <!-- <a @click="goToLastSection()">Jump To End</a> -->
    <full-page ref="fullpage" :options="options" id="fullpage">
      <!-- <div class="section" data-id="story1-1.1.1-1">
        <div class="fp-bg"></div>
        <div class="flex h-screen justify-center items-center">
          <div class="bg-orange p-3 w-full text-center">First section ...</div>
        </div>
      </div>
      <div class="section" data-id="story1-1.1.1-2">
        <div class="fp-bg"></div>
        <div class="bg-red-500 p-3">Second section Jakob Lipps ...</div>
      </div> -->
      <!-- 3_3.1.1 Mila in her bed, yawns. She gets up. It is a simple hotel-like room with a single bed and minimal equipment.  -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen relative">
          <h3 class="text-center pt-0 pb-4">Mila</h3>
          <div class="items-center">
            <div class="mb-5 w-1/3 mx-auto">
              <svg-310-mila-8-am
                ref="svg310mila8am"
                :key="svg310Mila8AmVal"
              ></svg-310-mila-8-am>
            </div>
          </div>
          <div class="text-center">
            <InitialLetterParagraph
              text="1 am (COT) /8 am (CET) A fresh day, a fresh start – substitution therapy as an opportunity"
              :background="['bg-blue']"
              :parentBackground="'bg-blue'"
            ></InitialLetterParagraph>
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <video-element
              ref="video311"
              :reference="'video311'"
              :src="
                require('../../assets/images/stories/story_3/Story3_3.1.1/3.1.1.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.1.1 -->
      <!-- 3_3.1.2 At the same plaza, but Mila is a teenager and among a group of young people who are drinking and smoking. -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <InitialLetterParagraph
              text="3 am (COT) /10 am (CET) When death changes everything"
              :background="['bg-blue']"
              :parentBackground="'bg-blue'"
            ></InitialLetterParagraph>
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <video-element
              ref="video312"
              :reference="'video312'"
              :src="
                require('../../assets/images/stories/story_3/Story3_3.1.2/3.1.2.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.1.2 -->
      <!-- 3_3.1.3 -->
      <fullpage-section :wrapperClasses="['bg-blue-301']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Mila was one of  more than 11 million people who inject drugs globally (Global State of Harm Reduction p.19)"
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="In 2019, almost half a million people lost their lives because of drug use or the consequences of drug use."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              src="~@/assets/images/stories/story_3/Story3_Infografik_3.1.3/Infografik_3.1.3_.svg"
              alt="Story image"
              class="mx-auto w-1/2"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.1.3 -->
      <!-- 3_3.1.3 2 -->
      <fullpage-section :wrapperClasses="['bg-blue-301']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Drug use comes with health risks, and overdoses are just one part of the big picture. "
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="Injecting drugs is associated with an increased risk of transmitting HIV and Hepatitis C. Of the total estimated more than 11 million people who inject drugs, 12.6% are infected with HIV and 48,5% with Hepatitis C (WDR, p.61). "
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <div class="pt-2">
            <img
              src="~@/assets/images/stories/story_3/Story3_Infografik_3.1.3/Infografik_3.1.3_2_.svg"
              alt="Story image"
              class="mx-auto w-1/2"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.1.3 2 -->
      <!-- 3_3.1.4 -->
      <fullpage-section :wrapperClasses="['bg-blue-301']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Buying drugs like cocaine and heroin has become simpler<br>Larger ship sizes, more shipping routes and private aircraft have made the coca supply chain more efficient. (WDR p.39)"
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="In 2018, the combined production for Colombia, Bolivia and Peru was more than double that of 2013 (Cocaine Pipeline to Europe p.1).<br>The amount of cocaine coming into Europe has increased over the years, while prices have decreased."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              src="~@/assets/images/stories/story_3/Story3_3.1.4/3.1.4.svg"
              alt="Story image"
              class="mx-auto w-3/4 md:w-1/2"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.1.4 -->
      <!-- 3_3.1.4 1-->
      <fullpage-section :wrapperClasses="['bg-blue-301']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Buying drugs like cocaine and heroin has become simpler<br>Larger ship sizes, more shipping routes and private aircraft have made the coca supply chain more efficient. (WDR p.39)"
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="In 2018, the combined production for Colombia, Bolivia and Peru was more than double that of 2013 (Cocaine Pipeline to Europe p.1).<br>The amount of cocaine coming into Europe has increased over the years, while prices have decreased."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <div class="pt-2">
            <img
              src="~@/assets/images/stories/story_3/Story3_3.1.4/infografik_3.1.4_1.svg"
              alt="Story image"
              class="mx-auto w-1/2 md:w-1/3"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.1.4 1-->
      <!-- 3_3.1.4 2 -->
      <fullpage-section :wrapperClasses="['bg-blue-301']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Heroin routes and prices in contrast have remained the same.<br>In 2019, 7.9 tonnes of heroin were seized in the EU. (European Drug Report 2021)"
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="In Germany, opioid consumption is the cause of 40% of all illicit drug-related deaths. Since this does not include the deaths caused by the long-term health consequences due to opioid consumption, the number of deaths is likely to be even higher. "
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <div class="pt-2">
            <img
              src="~@/assets/images/stories/story_3/Story3_3.1.4/infografik_3.1.4_2.svg"
              alt="Story image"
              class="mx-auto w-1/2 md:w-1/3"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.1.4 2 -->
      <!-- 3_3.1.5 -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="The illicit drug economy has a vested interest in the growing number of drug users in Europe.</br> In 2014, the global drug trafficking market was worth about"
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="426 billion to 652 billion US $."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="At the same time, online illicit drug markets are flourishing, making it even easier for people to buy illicit drugs. The dark web alone is worth $315 million (WDR p.10) in drug trading sums."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              src="~@/assets/images/stories/story_3/Story3_3.1.15/Story3_3.1.15-03.svg"
              alt="Story image"
              class="mx-auto w-1/2"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.1.5 -->
      <!-- 3_3.1.6 -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="This has led to devastating drug-related social and health impacts in Europe - Mila’s story is part of a larger development in Europe."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="The 2021-2025 EU Drugs Strategy recognises these challenges and includes drug use prevention and treatment as key elements. It also gives equal weight to harm reduction measures."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              src="~@/assets/images/stories/story_3/Story3_Infografik_3.1.6/Story3_3.1.6A_.svg"
              alt="Story image"
              class="mx-auto w-1/2"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.1.6 -->
      <!-- 3_3.1.6 2-->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Harm reduction aims to minimise the negative effects related to drug use. It is a holistic approach that takes into account health, social and legal aspects."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="But what does this look like in practice?"
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              src="~@/assets/images/stories/story_3/Story3_Infografik_3.1.6/Story3_3.1.6B_.svg"
              alt="Story image"
              class="mx-auto w-1/2"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.1.6 2-->
      <!-- 3_3.1.7 -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Explore the benefits of a human-rights centred and health-oriented approach to drug policy."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="Learn how harm reduction services can help people build a new life."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="See how it helped Mila escape the vicious cycle of drug dependence."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
        </div>
      </fullpage-section>
      <!-- 3_3.1.7 -->
      <!-- 3_3.2.1 Back of Mila’s head, she is walking around the city frantically. Stops at Kotti again.  -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <video-element
              ref="video321"
              :reference="'video321'"
              :src="
                require('../../assets/images/stories/story_3/Story3_3.2.1/3.2.1.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.2.1 -->
      <!-- 3_3.2.2 Same close up of Mila’s face again, but no tears. She is still walking but looks more relaxed.  -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <video-element
              ref="video322"
              :reference="'video322'"
              :src="
                require('../../assets/images/stories/story_3/Story3_3.2.2/3.2.2.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.2.2 -->
      <!-- 3_3.2.3 Birkenstube virtual tour  -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <p>Do you want to visit the "Birkenstube" virtual tour?</p>
            <button
              class="
                flex
                shadow
                mx-auto
                my-4
                w-32
                block
                border-white border-2
                rounded-full
                focus:outline-none focus:border-white
                px-4
                py-2
                text-white
                hover:bg-orange-500 hover:text-white
              "
              @click="openHarmReduction()"
            >
              <span class="mx-auto">Yes</span>
            </button>
            <button
              class="
                flex
                shadow
                mx-auto
                my-4
                w-32
                block
                border-white border-2
                rounded-full
                focus:outline-none focus:border-white
                px-4
                py-2
                text-white
                hover:bg-orange-500 hover:text-white
              "
              @click="goToNextSection()"
            >
              <span class="mx-auto">No</span>
            </button>
          </div>
        </div>
      </fullpage-section>

      <!-- 3_3.2.3 -->
      <!-- 3_3.2.4 -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Drug dependence is recognised as a disorder by the World Health Organization. Understanding that addiction is a medical issue helps address it in a holistic way."
            :background="['bg-orange']"
          ></InitialLetterParagraph>
        </div>
      </fullpage-section>
      <!-- 3_3.2.4 -->
      <!-- 3_3.2.5 Mit Videostartbild Dr. Bohr O-Ton 10.11.2021  / oder Praxisschild aus Rundgang präsentieren  -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <InitialLetterParagraph
              text="Introduction to Addiction"
              :background="['bg-blue']"
              :parentBackground="'bg-blue'"
            ></InitialLetterParagraph>
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <video-element
              ref="video325"
              :reference="'video325'"
              :src="
                require('../../assets/images/stories/story_3/Story3_3.2.5/3.2.5._JVA Snippets-drbohr.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.2.5 -->
      <!-- 3_3.2.6 Two hands appear -->
      <fullpage-section :wrapperClasses="['bg-blue']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="In order to help people who use illicit drugs, harm reduction services must be inclusive, non-judgmental and non-stigmatizing."
            :background="['bg-blue-600']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="Services must also be tailored to vulnerable and disadvantaged groups."
            :background="['bg-blue-600']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              class="mx-auto w-5/6 md:w-3/4"
              src="~@/assets/images/stories/story_3/Story3_3.2.6/story3_3.2.6.svg"
              alt="watches her children leave"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.2.6 -->
      <!-- 3_3.2.7 Harm reduction -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Harm reduction is an umbrella term for a variety of public health programmes and measures. The services a facility offers depends on the local needs and financial constraints."
            :background="['bg-blue-600']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="Services can range from the distribution of sterile needles and syringes to drug users on the streets, to checking the main components of their drugs, as well as providing housing."
            :background="['bg-blue-600']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="Harm reduction services are often the first point of contact with social workers and medical professionals. They can refer them to further services, such as social and psychological support, or even rehabilitation. These assist them in breaking the vicious cycle of addiction."
            :background="['bg-blue-600']"
          ></InitialLetterParagraph>
          <p>
            <a
              href="https://harmreduction.org/issues/safer-drug-use/"
              target="_blank"
              >Source</a
            >
          </p>
        </div>
      </fullpage-section>
      <!-- 3_3.2.7 -->
      <!-- 3_3.2.8 Icon of first aid kit with bandages, syringes and medics cross on it -->
      <fullpage-section :wrapperClasses="['bg-pink-501']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="One central component of harm reduction is advocating for safer drug use. It tries to reduce the risk of adverse outcomes from using drugs."
            :background="['bg-pink']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center bg-pink-501">
            <img
              class="bg-pink-501 mx-auto w-5/6"
              src="~@/assets/images/stories/story_3/Story3_3.2.8/story3_3.2.8_iconA.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-pink-501']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Needle and Syringe programs (NSPs) are one aspect of this, providing users with safe equipment to inject drugs. This reduces the probability of drug users contracting HIV or Hepatitis C."
            :background="['bg-pink']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center bg-pink-501">
            <img
              class="mx-auto w-5/6"
              src="~@/assets/images/stories/story_3/Story3_3.2.8/Story3_3.2.8_A.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-pink-501']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Drug consumption rooms (DCRs) allow people to consume the illicit drugs that they bring along in a safe environment. They are supervised by professional health care staff and are also key to preventing overdoses and HIV or Hepatitis C transmission.<br><br> These evidence-based harm reduction strategies are proven to reduce the risk of overdose and infections related to drug use."
            :background="['bg-pink']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center bg-pink-501">
            <img
              class="mx-auto w-full"
              src="~@/assets/images/stories/story_3/Story3_3.2.8/Story3_3.2.8_B.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-pink-501']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Another instrument is drug checking. It allows users to have the main components and quality of their illicit drugs analysed, allowing people to adjust their consumption in a safer manner or even discard adulterated samples. This is particularly important for people who use stimulants, as the concentration of substances found in illicit drugs is usually not known."
            :background="['bg-pink']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center bg-pink-501">
            <img
              class="mx-auto w-full"
              src="~@/assets/images/stories/story_3/Story3_3.2.8/Story3_3.2.8_C.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-pink-501']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="This approach can raise awareness about the risks associated with specific drugs. In some European countries drug checking is already available.<br>However, the legal framework for providing such services is lacking in many countries.<br>In this respect, the promotion of harm reduction measures is a key component of the EU Drug Strategy."
            :background="['bg-pink']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              class="bg-pink-501 mx-auto w-full"
              src="~@/assets/images/stories/story_3/Story3_3.2.8/Story3_3.2.8_C.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.2.8 -->
      <!-- 3_3.2.9 Illustration of Mila at the reception of storage room (behind her there are racks of clothing), -->
      <fullpage-section :wrapperClasses="['bg-yellow-301']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="9 am (COT)/4pm (CET) Finding a new place in the world"
            :background="['bg-blue-200']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="After going on substitution therapy, I had so much time to fill. Suddenly my day seemed so long without chasing after drugs. My health got better, and I started volunteering at this day care centre for homeless people. I help out where help is needed: Usually, I work in the storage room, handing out fresh clothes. I listen to people when they want to talk."
            :background="['bg-blue-600']"
          ></InitialLetterParagraph>
          <audio-element
            ref="audio329"
            :reference="'audio329'"
            :src="
              require('../../assets/audio/stories/story_3/Sounds_Voice-Overs/3.2.9.mp3')
            "
          ></audio-element>
          <div class="flex h-screen justify-center items-center">
            <img
              class="mx-auto w-3/4"
              src="~@/assets/images/stories/story_3/Story3_3.2.9/Story3_3.2.9_A.svg"
              alt="watches her children leave"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-yellow-301']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Knowing that I am useful and helping people in a situation similar to the one I used to be in makes me happy. Back then I needed help myself, today I help others.<br>I wouldn’t be where I am without the support facility. They also referred me to a programme that helped me apply for a vocational training. My volunteering experience helped me get the spot to train as a social assistant."
            :background="['bg-blue-600']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              class="mx-auto w-3/4"
              src="~@/assets/images/stories/story_3/Story3_3.2.9/Story3_3.2.9_B.svg"
              alt="watches her children leave"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.2.9 -->
      <!-- 3_3.2.10 Video O-Ton Dr. Bohr -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <InitialLetterParagraph
              text="Substitution Therapy"
              :background="['bg-blue']"
              :parentBackground="'bg-blue'"
            ></InitialLetterParagraph>
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <video-element
              ref="video32101"
              :reference="'video32101'"
              :src="
                require('../../assets/images/stories/story_3/Story3_3.2.10/3.2.10-1_JVA Snippets-drbohr.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.2.10 -->
      <!-- 3_3.2.10 Video O-Ton Dr. Bohr -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <InitialLetterParagraph
              text="Treatment goals"
              :background="['bg-blue']"
              :parentBackground="'bg-blue'"
            ></InitialLetterParagraph>
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <video-element
              ref="video32102"
              :reference="'video32102'"
              :src="
                require('../../assets/images/stories/story_3/Story3_3.2.10/3.2.10-2_DrBohr_Behandlungsziele.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.2.10 -->
      <!-- 3_3.2.11 -->
      <fullpage-section :wrapperClasses="['bg-pink-501']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="The availability of harm reduction services varies greatly worldwide. Take the example of Opioid Agonist Therapy (OAT) also known as substitution therapy, which Mila receives to treat her heroin addiction. OAT medication mimics heroin and produces a similar response,<br><br>….preventing withdrawal symptoms and reducing cravings without producing the typical “high” or “kick” associated with heroin use."
            :background="['bg-pink']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              class="mx-auto w-3/4"
              src="~@/assets/images/stories/story_3/Story3_Infografik_3.2.11/Infografik_3.2.11-03.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.2.11 -->
      <!-- 3_3.2.11 2 -->
      <fullpage-section :wrapperClasses="['bg-pink-501']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="This helps drug users stabilise their lives and  reduce the harm related to their drug use. In addition, they no longer have to obtain the drugs and money illegally or live with the fear of prosecution. As with any addiction, relapses may occur. For these cases, there is the life-saving emergency medication naloxone."
            :background="['bg-pink']"
          ></InitialLetterParagraph>
        </div>
      </fullpage-section>
      <!-- 3_3.2.11 2 -->
      <!-- 3_3.2.12 Illustration of Mila and Sophia talking together at a table -->
      <fullpage-section :wrapperClasses="['bg-yellow-301']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Sophia, the woman who picked me up after Nina's death, also looks after people coming out of prison. It’s a collaboration between the support facility and the prison. She goes to see them in the run up to their release, and on the basis of my experiences with Nina I give her advice on what they might need."
            :background="['bg-blue-600']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              class="mx-auto w-3/4 md:w-2/3"
              src="~@/assets/images/stories/story_3/Story3_3.2.12/Story3_3.2.12_A.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-blue-301']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="For example, in the prison Sophia cooperates with inmates who receive substitution therapy. She organises things so that they continue receiving it upon release. I always think of Nina and how things would have been if she had received support."
            :background="['bg-blue-600']"
          ></InitialLetterParagraph>
          <img
            class="absolute bottom-0"
            src="~@/assets/images/stories/story_3/Story3_3.2.12/Story3_3.2.12_B.svg"
          />
        </div>
      </fullpage-section>
      <!-- 3_3.2.12 -->
      <!-- 3_3.2.13 Einblick in die praktische Arbeit der medizinischen Abteilung, des Sozialdienstes und des allgm. Vollzugs in Bezug zu „Suchtkranken und Substitution“ -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <InitialLetterParagraph
              text="The problem of addiction"
              :background="['bg-blue']"
              :parentBackground="'bg-blue'"
            ></InitialLetterParagraph>
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <video-element
              ref="video32131"
              :reference="'video32131'"
              :src="
                require('../../assets/images/stories/story_3/Story3_3.2.13/3.2.13-1_Problem Sucht.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <InitialLetterParagraph
              text="Cooperation"
              :background="['bg-blue']"
              :parentBackground="'bg-blue'"
            ></InitialLetterParagraph>
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <video-element
              ref="video32132"
              :reference="'video32132'"
              :src="
                require('../../assets/images/stories/story_3/Story3_3.2.13/3.2.13-2_Zusammenarbeit.mp4')
              "
              @videoplaying="handleBgAudio"
            ></video-element>
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.2.13 -->
      <!-- 3_3.2.13 JVA Rundgang-->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <p>Do you want to do a full tour of the JVA?</p>
            <button
              class="
                flex
                shadow
                mx-auto
                my-4
                w-32
                block
                border-white border-2
                rounded-full
                focus:outline-none focus:border-white
                px-4
                py-2
                text-white
                hover:bg-orange-500 hover:text-white
              "
              @click="openJvaLink()"
            >
              <span class="mx-auto">Yes</span>
            </button>
            <button
              class="
                flex
                shadow
                mx-auto
                my-4
                w-32
                block
                border-white border-2
                rounded-full
                focus:outline-none focus:border-white
                px-4
                py-2
                text-white
                hover:bg-orange-500 hover:text-white
              "
              @click="goToNextSection()"
            >
              <span class="mx-auto">No</span>
            </button>
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.2.13 JVA Rundgang-->

      <!-- 3_3.2.14 -->
      <fullpage-section :wrapperClasses="['bg-blue-301']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="If a naloxone overdose training programme had existed in the prison that Nina was released from, her death could have been prevented. Naloxone spray could have reversed the effects of the overdose."
            :background="['bg-blue-600']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              class="absolute bottom-0 mx-auto w-full md:w-3/4"
              src="~@/assets/images/stories/story_3/Story3_3.2.14/Story3_3.2.14.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.2.14 -->
      <!-- 3_3.2.14 2 -->
      <fullpage-section :wrapperClasses="['bg-blue-301']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Research has shown that drug users who have been released from prison have an increased risk of dying from an overdose. Long periods of abstinence and a lack of opioid substitution therapy (OST) coverage in prisons make overdoses upon release more likely.<br><br>And if OST is available, the period shortly after release, during which people may not have access to OST straight away, can lead to relapse or overdose. Although some institutions, like the one Sophia is working with, are leading the way, more action is needed.<br><br>People who use illicit drugs are more likely to be incarcerated at some stage during their life because of illegal drug possession or drug related crimes. Drug use is widespread in prison settings. A third of people in prisons worldwide is estimated to have used illicit drugs at least once while incarcerated (Global Harm reduction report, UNAIDS).<br><br>At the same time, the world prison population has grown by 24% since 2000, making this a major concern.<br><br>Women in particular are affected by negative health and social outcomes once incarcerated."
            :background="['bg-blue-600']"
          ></InitialLetterParagraph>
        </div>
      </fullpage-section>
      <!-- 3_3.2.14 2 -->
      <!-- 3_3.2.15 -->
      <fullpage-section :wrapperClasses="['bg-blue-301']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Providing testing and treatment services in prisons is a human rights obligation. Prisons around the world offer various harm reduction services, and needle and syringe programmes in prisons are available in 10 countries. "
            :background="['bg-blue-600']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="In this sense, the EU Drugs Strategy addresses the health and social needs of people who use drugs in prisons."
            :background="['bg-blue-600']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="It aims to use harm-reduction measures to:"
            :background="['bg-blue-600']"
          ></InitialLetterParagraph>
          <ul class="list-disc p-8 bg-blue-600">
            <li>
              prevent the use of drugs and the transmission of blood-borne
              infections in prisons
            </li>
            <li>
              provide access to testing and treatment for blood-borne infections
              and other measures that reduce the health risks associated with
              drug use in prison, and
            </li>
            <li>
              implement overdose awareness training and distribute naloxone to
              people once they leave prison.
            </li>
          </ul>
        </div>
      </fullpage-section>
      <!-- 3_3.2.15 -->
      <!-- 3_3.2.16 Mila in café, having a cup of coffe. She looks content. -->
      <fullpage-section :wrapperClasses="['bg-blue-201']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="In several weeks I will finally start my vocational training as social assistant. Then I can start looking for a flat and become fully independent. I am itching to start this new chapter in my life. I can’t go back to who I was before."
            :background="['bg-blue-500']"
          ></InitialLetterParagraph>
          <audio-element
            ref="audio326"
            :reference="'audio326'"
            :src="
              require('../../assets/audio/stories/story_3/Sounds_Voice-Overs/3.2.16.mp3')
            "
          ></audio-element>
          <div class="flex h-screen justify-center items-center">
            <div class="text-center">
              <img
                class="mx-auto w-full"
                src="~@/assets/images/stories/story_3/Story3_3.2.16/Story3_3.2.16.svg"
              />
            </div>
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.2.16 -->
      <!-- 3_3.2.17 Mila in café, having a cup of coffe. She looks content. -->
      <fullpage-section :wrapperClasses="['bg-blue-201']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="Both inside and outside of prisons, harm reduction services that go beyond promoting safer drug use are needed."
            :background="['bg-blue-500']"
          ></InitialLetterParagraph>
          <InitialLetterParagraph
            text="Rehabilitation services have helped Mila overcome her addiction and start to build a sustainable life."
            :background="['bg-blue-500']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              class="mx-auto w-full"
              src="~@/assets/images/stories/story_3/Story3_3.2.17/Story3_3.2.17_A.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-blue-201']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="By including rehabilitation and treatment as part of its response to illicit drug use, the EU Drugs Strategy sets out a comprehensive way forward. It paves the way for a more human-centred and evidence-based approach to drug policy."
            :background="['bg-blue-500']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              class="mx-auto w-5/6 md:w-full"
              src="~@/assets/images/stories/story_3/Story3_3.2.17/Story3_3.2.17_B.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-pink-501']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="The strategy is just the beginning. It is now time to make it a reality."
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              class="mx-auto w-full"
              src="~@/assets/images/stories/story_3/Story3_3.2.17/Story3_3.2.17_E.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.2.17 -->
      <!-- 3_3.4.1 Closing statement -->
      <fullpage-section :wrapperClasses="['bg-yellow-301']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="The illicit drug trade is a complex issue involving many actors. Comprehensive solutions and joint efforts from the international community are needed to address it. The EU Drugs Strategy is an important milestone in the global journey towards human-centred drug policies. It aims to counteract the global drug trade and organised crime."
            :background="['bg-yellow-500']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              class="mx-auto w-5/6 md:w-full"
              src="~@/assets/images/stories/story_3/Story3_3.4.1/Story3_3.4.1A.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-yellow-301']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="It sets out a balanced approach that focuses on the people affected by the illicit drug trade, while tackling it at its roots by… advancing alternative development in illicit drug crop cultivation areas, as well as…"
            :background="['bg-yellow-500']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              class="mx-auto w-full"
              src="~@/assets/images/stories/story_3/Story3_3.4.1/Story3_3.4.1B.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-pink-501']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="…promoting drug use prevention and harm reduction measures worldwide."
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              class="mx-auto w-1/4"
              src="~@/assets/images/stories/story_3/Story3_3.4.1/Story3_3.4.1C.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-pink-501']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="A better future lies in our hands. A future that empowers people to build dignified and sustainable lives beyond the illicit drug economy. We must keep up the momentum to make this a reality."
            :background="['bg-yellow-500']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              class="mx-auto w-3/4"
              src="~@/assets/images/stories/story_3/Story3_3.4.1/Story3_3.4.1D.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-pink-501']">
        <div class="flex flex-col h-screen-without-header">
          <InitialLetterParagraph
            text="A better future lies in our hands. A future that empowers people to build dignified and sustainable lives beyond the illicit drug economy. We must keep up the momentum to make this a reality."
            :background="['bg-yellow-500']"
          ></InitialLetterParagraph>
          <div class="flex h-screen justify-center items-center">
            <img
              class="mx-auto w-5/6 md:w-1/2"
              src="~@/assets/images/stories/story_3/Story3_3.4.1/Story3_3.4.1F.svg"
            />
          </div>
        </div>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-pink-501']">
        <div class="flex flex-col h-screen-without-header">
          <div class="flex h-screen justify-center items-center">
            <div class="mt-4 grid grid-cols-2 gap-4">
              <router-link to="/story-ana">
                <img
                  src="~@/assets/images/stories/intro/Intro_01_Clocks/01.Clocks_fullsize_Ana.svg"
                  alt="story image"
                  class="w-full px-2 md:w-2/3 mx-auto"
                />
                <h3 class="text-center text-2xl pb-2">Ana</h3>
              </router-link>
              <router-link to="/story-kofi">
                <img
                  src="~@/assets/images/stories/intro/Intro_01_Clocks/01.Clocks_fullsize_Kofi.svg"
                  alt="story image"
                  class="w-full px-2 md:w-2/3 mx-auto"
                />
                <h3 class="text-center text-2xl pb-2">Kofi</h3>
              </router-link>
            </div>
          </div>
        </div>
      </fullpage-section>
      <!-- 3_3.4.1 -->
      <audio-background-element
        ref="bgaudio"
        :reference="'bgaudio'"
        :src="
          require('../../assets/audio/stories/story_3/bg_sound/mila_generel_mixdown.mp3')
        "
      ></audio-background-element>
    </full-page>
  </div>
</template>

<script>
import FullpageSection from "../FullpageSection";
import InitialLetterParagraph from "../InitialLetterParagraph";
import VideoElement from "../VideoElement.vue";
import AudioElement from "../AudioElement.vue";
import AudioBackgroundElement from "../AudioBackgroundElement.vue";
import Svg310Mila8Am from "../svg/Svg310Mila8Am.vue";

export default {
  name: "Story1FullpageWrapper",
  components: {
    FullpageSection,
    InitialLetterParagraph,
    VideoElement,
    AudioElement,
    AudioBackgroundElement,
    Svg310Mila8Am,
  },
  data() {
    return {
      options: {
        licenseKey: "1806D743-4E124AA5-92F132F2-321A5FA4",
        // menu: "#menu",
        // sectionsColor: ["#41b883", "#ff5f45", "#0798ec"],
        // parallaxKey: "a29udHJvbGxmZWxkLm5ldF9kUXBjR0Z5WVd4c1lYZz1NOEE=",
        // parallax: true,
        // parallaxOptions: { percentage: 62 },
        fadingEffect: true,
        fadingEffectKey: "a29udHJvbGxmZWxkLm5ldF83WVNabUZrYVc1blJXWm1aV04wQUF2",
        paddingTop: "2.5rem",
        afterLoad: this.afterLoad,
        onLeave: this.onLeave,
      },
      svg310Mila8AmVal: 0,
    };
  },
  methods: {
    goToLastSection() {
      this.$refs.fullpage.api.moveTo("ana-section-37");
    },
    goToSectionFromParam(index) {
      if (index) {
        this.$refs.fullpage.api.moveTo(index);
      }
    },
    goToNextSection() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, "section")) {
        this.goToSectionFromParam(parseInt(this.$route.query.section) + 1);
      } else {
        this.goToSectionFromParam(2);
      }
    },
    openJvaLink() {
      window.open("https://momentum.kontrollfeld.net/jva/");
    },
    openHarmReduction() {
      window.open("http://harmreduction.ndc-euro2020.de");
    },
    updateBodyBackground(bgColorClass) {
      this.removeBackgroundClassFromBody();
      document.body.classList.add(bgColorClass);
    },
    removeBackgroundClassFromBody() {
      document.body.classList.forEach(function (val, index, listObj) {
        if (val.startsWith("bg-")) {
          listObj.remove(val);
        }
      });
    },
    getBgClassFromSectionWithIndex(index) {
      return this.$children[0].$children[index].$options.propsData
        .wrapperClasses[0];
    },
    /* eslint-disable no-unused-vars */
    afterLoad(origin, destination, direction) {
      // console.log("'after load' origin:", origin);
      // console.log("'after load' destination:", destination);
      // console.log("'after load' direction:", direction);

      let originSectionIndex = origin.index + 1;
      let destinationSectionIndex = destination.index + 1;
      console.log("'originSectionIndex:", originSectionIndex);
      console.log("'destinationSectionIndex:", destinationSectionIndex);

      // If is intial load, do not replace section query.
      if (direction) {
        this.$router
          .replace({ query: { section: destinationSectionIndex } })
          .catch((err) => {});
      }
      switch (destinationSectionIndex) {
        case 1:
          this.$refs["video311"].playMedia();
          this.svg310Mila8AmVal = !this.svg310Mila8AmVal;
          this.$refs["svg310mila8am"].animate();
          break;
        case 2:
          this.$refs["video312"].playMedia();
          break;
        case 12:
          this.$refs["video321"].playMedia();
          break;
        case 13:
          this.$refs["video322"].playMedia();
          break;
        case 16:
          this.$refs["video325"].playMedia();
          break;
        case 24:
          this.$refs["audio329"].playMedia();
          break;
        case 26:
          this.$refs["video32101"].playMedia();
          break;
        case 27:
          this.$refs["video32102"].playMedia();
          break;
        case 32:
          this.$refs["video32131"].playMedia();
          break;
        case 33:
          this.$refs["video32132"].playMedia();
          break;
        case 38:
          this.$refs["audio326"].playMedia();
          break;

        default:
          break;
      }

      switch (originSectionIndex) {
        case 1:
          this.$refs["video311"].pauseMedia();
          break;
        case 2:
          this.$refs["video312"].pauseMedia();
          break;
        case 12:
          this.$refs["video321"].pauseMedia();
          break;
        case 13:
          this.$refs["video322"].pauseMedia();
          break;
        case 16:
          this.$refs["video325"].pauseMedia();
          break;
        case 24:
          this.$refs["audio329"].pauseMedia();
          break;
        case 26:
          this.$refs["video32101"].pauseMedia();
          break;
        case 27:
          this.$refs["video32102"].pauseMedia();
          break;
        case 32:
          this.$refs["video32131"].pauseMedia();
          break;
        case 33:
          this.$refs["video32132"].pauseMedia();
          break;
        case 38:
          this.$refs["audio326"].pauseMedia();
          break;

        default:
          break;
      }
    },
    onLeave(origin, destination, direction) {
      let destinationSectionIndex = destination.index + 1;
      this.updateBodyBackground(
        this.getBgClassFromSectionWithIndex(destination.index)
      );

      switch (destinationSectionIndex) {
        case 1:
          // this.svg1111Clock5AmVal = !this.svg1111Clock5AmVal;
          // this.$refs["svg1111clock5am"].animate();
          break;
        case 3:
          break;

        default:
          break;
      }
    },
    /* eslint-enable no-unused-vars */
    handleBgAudio(videoPlaying) {
      console.log("handleBgAudio", videoPlaying);
      if (videoPlaying) {
        this.pauseBgAudio();
      } else {
        this.playBgAudio();
      }
    },
    playBgAudio() {
      console.log("play BgAudio");
      this.$refs["bgaudio"].playMedia();
    },
    pauseBgAudio() {
      this.$refs["bgaudio"].pauseMedia();
    },
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.$route.query, "section")) {
      this.goToSectionFromParam(this.$route.query.section);
    }
    let section = this.$route.query.section
      ? parseInt(this.$route.query.section) - 1
      : 1;
    this.updateBodyBackground(this.getBgClassFromSectionWithIndex(section));
    this.playBgAudio();
  },
  /* eslint-disable no-unused-vars */
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    console.info("beforeRouteLeave to", to);
    if (to.name != "StoryMila") {
      this.pauseBgAudio();
    }
    next();
  },
  /* eslint-enable no-unused-vars */
  beforeRouteUpdate(to, from, next) {
    let sectionTo;
    let sectionFrom;
    if (Object.prototype.hasOwnProperty.call(to.query, "section")) {
      sectionTo = parseInt(to.query.section);
    }
    if (Object.prototype.hasOwnProperty.call(from.query, "section")) {
      sectionFrom = parseInt(from.query.section);
    }
    if (sectionTo || sectionFrom) {
      if (sectionTo != sectionFrom) {
        console.log("sectionTo", sectionTo);
        if (!sectionTo) {
          sectionTo = 1;
        }
        this.goToSectionFromParam(sectionTo);
      }
    }
    next();
  },
};
</script>

<style>
/* .section .fp-bg {
  background-size: cover;
  background-position: center 80%;
}
.section[data-id="story1-1.1.1-1"] .fp-bg {
  background-image: url("~@/assets/images/stories/story_1/Story_1_1.1.1/Story1_1.1.1_1.svg");
}
.section[data-id="story1-1.1.1-2"] .fp-bg {
  background-image: url("~@/assets/images/stories/story_1/Story_1_1.1.1/Story1_1.1.1_2.svg");
} */
</style>
